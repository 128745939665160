import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Chip from '../chip/Chip'
import { FavoriteEvent } from '../favorite-event/favorite-event'
import { getIconName } from '../icon/icon.helper'
import { Icon } from '../icon/icon'
import { NavLink } from '../internal-nav-link/Internal-nav-link'
import LoginModal from '../login-modal/login-modal'
import Text from '../text/text'
import { getImgSrc } from '~/utils/image'
import { cn } from '~/utils/misc'
import { getEventDateUrl } from '~/utils/seo/seo-misc'
import { trackClick } from '~/utils/statistics/statistics.helper'
import { useOptionalUser } from '~/utils/user'
import type { EventIndex } from '~/types/eventIndex'

export default function EventListSwiper({
	events,
	type,
}: {
	events: EventIndex[]
	type?: 'promoted' | 'popular'
}) {
	const [showLoginModal, setShowLoginModal] = useState(false)
	const user = useOptionalUser()
	const { t } = useTranslation()
	const toggleModal = () => setShowLoginModal(prev => !prev)

	const lengthThree = events.length === 3
	return (
		<>
			{showLoginModal && (
				<LoginModal
					text={t('misc_login_to_add_favorite')}
					toggleLoginModal={toggleModal}
					initialPage={0}
				/>
			)}

			<Swiper
				pagination={{
					clickable: true,
				}}
				slidesPerView="auto"
				centeredSlides
				centeredSlidesBounds
				modules={[Pagination]}
				breakpoints={{
					1: {
						slidesPerGroup: 1,
					},
					768: {
						slidesPerGroup: 2,
					},
					1280: {
						slidesPerGroup: lengthThree ? 3 : 2,
					},
				}}
				className="-mx-3 pb-[35px]"
			>
				{events.map(x => {
					return (
						<SwiperSlide
							key={x.dateId}
							className={cn(
								'w-[90%] !transition-opacity !duration-200 first:ml-0 last:mr-0 hover:opacity-80 md:w-[45%] xl:w-[30%]',
								lengthThree ? 'xl:w-1/3' : 'xl:w-[30%]',
							)}
						>
							<div className="relative mx-3">
								{user ? (
									<div className="absolute right-0 top-1 z-[1] transition duration-200 hover:scale-105">
										<FavoriteEvent
											favoredByUser={
												user?.favorites.some(e => e.id === x.dateId) ?? false
											}
											eventDateId={x.dateId}
											eventName={x.title}
											tabIndex={false}
										/>
									</div>
								) : (
									<div className="absolute right-4 top-3 z-[1] transition duration-200 hover:scale-105">
										<div>
											<button
												tabIndex={-1}
												aria-label={t('action_add_as_favorite', {
													title: x.title,
												})}
												className="justify-centertext-foreground/40 items-center dark:text-background/50"
												onClick={toggleModal}
											>
												<Icon
													className="stoke-1 scale-125 stroke-white max-md:scale-150"
													name="heart-2"
													size="md"
												/>
											</button>
										</div>
									</div>
								)}
								<NavLink
									className="flex flex-col"
									tabIndex={-1}
									to={getEventDateUrl(x.dateId, x.title)}
									state={{ from: 'internal' }}
									onClick={() => type && trackClick(x.dateId, type)}
									viewTransition
								>
									{({ isTransitioning }) => (
										<>
											<div
												className="relative w-full select-none"
												style={
													isTransitioning
														? {
																viewTransitionName: 'image-expand',
															}
														: undefined
												}
											>
												<img
													src={getImgSrc(x?.imageId, 'event')}
													alt={x?.title}
													className={cn(
														'aspect-video w-full rounded-t-xl object-cover',
													)}
													loading="lazy"
												/>

												<Chip
													className="!absolute left-0 top-0 !rounded-bl-none !rounded-tr-none"
													text={
														<div className="flex items-center">
															<Icon
																name={getIconName(x.category.icon)}
																size="xs"
																className="mr-1"
															/>
															{x.category.name}
														</div>
													}
													color={x.category.color}
												/>
												<div className="absolute bottom-0 left-0 right-0 top-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent"></div>
												<div
													className="absolute bottom-2 left-2 right-2"
													style={
														isTransitioning
															? {
																	viewTransitionName: 'title-expand',
																}
															: undefined
													}
												>
													<Text
														as="h3"
														className="truncate !text-h2 !leading-6 text-white drop-shadow-xl"
													>
														{x.title}
													</Text>
												</div>
											</div>
											<div className="rounded-b-xl border-b border-l border-r border-border bg-card p-2">
												{x.location && (
													<Text className="truncate font-semibold">
														{x.location}
													</Text>
												)}
												{x.dateTime && (
													<span className="flex justify-between truncate align-middle">
														<Text as="sm">{x.dateTime}</Text>
														{x.newDate && (
															<Chip
																text="Nytt datum"
																size="xs"
																color="indigo"
															/>
														)}
													</span>
												)}
											</div>
										</>
									)}
								</NavLink>
							</div>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</>
	)
}
