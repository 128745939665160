import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '~/components/internal-link/Internal-link'
import Text from '~/components/text/text'

export const IndexTitle = ({
	title,
	mainTitle = false,
	showMoreUrl,
}: {
	title: React.ReactNode
	mainTitle?: boolean
	showMoreUrl?: string
}) => {
	const { t } = useTranslation()
	return (
		<div className="flex items-center justify-between">
			<Text as={mainTitle ? 'h1' : 'h2'} className="my-2 !text-h2 md:!text-h1">
				{title}
			</Text>
			{showMoreUrl && (
				<Link to={showMoreUrl} className="underline">
					{t('action_show_more')}
				</Link>
			)}
		</div>
	)
}

export const IndexSection = ({ children }: { children: React.ReactNode }) => {
	return <section className="mb-10">{children}</section>
}
